



































































import {
  computed,
  defineComponent,
  PropType,
  ref,
  toRefs
} from '@nuxtjs/composition-api'
import { useId } from '~/compositions/id'
import { vue3Model } from '~/utils/nuxt3-migration'

export default defineComponent({
  model: vue3Model,
  props: {
    value: {
      type: [Array, Boolean, String, Number],
      default: null
    },
    modelValue: {
      type: [Boolean, String, Array, Number],
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    switch: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: null
    },
    reverse: {
      type: Boolean,
      default: false
    },
    indeterminate: {
      type: Boolean,
      default: false
    },
    externalLabelClasses: {
      type: String,
      required: false,
      default: ''
    },
    variant: {
      type: String as PropType<'primary' | 'blue'>,
      default: 'primary'
    },
    withBorder: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const {
      id,
      disabled,
      switch: switchProp,
      large,
      reverse,
      variant,
      externalLabelClasses,
      withBorder,
      value
    } = toRefs(props)
    const { createRandomId } = useId()

    const checkboxTemplateRef = ref<HTMLElement | null>(null)
    const internalId = id.value ?? createRandomId()

    const internalValue = computed({
      get() {
        return props.modelValue
      },
      set(value) {
        emit('update:modelValue', value)
        emit('change', value)
      }
    })

    const checkboxClasses = computed(() => {
      const classes = []

      if (disabled.value) {
        classes.push('tw-bg-grey-300 tw-border-grey-300 checked:tw-opacity-50')
      } else {
        classes.push('tw-border-grey-400')
        withBorder.value
          ? classes.push('!tw-ring-transparent')
          : classes.push(
              'focus-visible:tw-ring-2 focus-visible:tw-ring-blue-200'
            )

        classes.push(
          variant.value === 'blue'
            ? 'group-hover/checkbox:tw-border-blue'
            : 'group-hover/checkbox:tw-border-primary'
        )
      }

      if (variant.value === 'blue') {
        classes.push('tw-text-blue')
      } else {
        classes.push('tw-text-primary')
      }

      if (switchProp.value) {
        classes.push('tw-sr-only')
      }

      if (large.value) {
        classes.push('tw-h-6 tw-w-6 tw-rounded-md')
      } else {
        classes.push('tw-h-4 tw-w-4 tw-rounded')
      }

      if (withBorder.value) {
        classes.push('tw-z-[1]')
      }

      return [...classes, cursorClass.value]
    })

    const labelClasses = computed(() => {
      const classes = []

      if (disabled.value) {
        classes.push('tw-text-grey-600')
      } else {
        classes.push(' tw-text-grey-900')
        if (props.variant === 'blue') {
          classes.push(
            withBorder.value
              ? 'group-hover/checkbox:tw-border-blue-500'
              : 'group-hover/checkbox:lg:tw-text-blue'
          )
        } else {
          classes.push(
            withBorder.value
              ? 'group-hover/checkbox:tw-border-primary'
              : 'group-hover/checkbox:lg:tw-text-primary'
          )
        }
      }

      if (!reverse.value) {
        classes.push('tw-w-full')
      }

      if (withBorder.value) {
        classes.push('tw-z-[1] tw-line-clamp-2')
      }

      if (large.value) {
        classes.push('tw-text-lg')
      } else {
        classes.push('tw-text-base')
      }

      return [...classes, externalLabelClasses.value, cursorClass.value]
    })

    const switchButtonClasses = computed(() => {
      const classes = []

      if (disabled.value) {
        classes.push('tw-opacity-50 tw-border-grey-300')
      } else {
        classes.push('tw-border-grey-300 ')
        withBorder.value
          ? classes.push('!tw-ring-transparent')
          : classes.push(
              'focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-ring-offer-blue-200'
            )
        classes.push(
          variant.value === 'blue'
            ? 'group-hover/checkbox:tw-border-blue'
            : 'group-hover/checkbox:tw-border-primary'
        )
      }

      if (large.value) {
        classes.push('tw-h-6 tw-w-12')
      } else {
        classes.push('tw-h-4 tw-w-8')
      }

      if (withBorder.value) {
        classes.push('tw-z-[1]')
      }

      return [...classes, cursorClass.value]
    })

    const checkboxContainerClasses = computed(() => {
      const classes = []

      if (large.value) {
        classes.push('tw-h-6')
      } else {
        classes.push('tw-h-4')
      }

      return classes
    })

    const switchButtonToggleClasses = computed(() => {
      const classes = []

      if (large.value) {
        classes.push('tw-h-5 tw-w-5')
      } else {
        classes.push('tw-h-3 tw-w-3')
      }

      return classes
    })

    const cursorClass = computed(() => {
      return disabled.value ? 'tw-cursor-not-allowed' : 'tw-cursor-pointer'
    })

    const roundnessClass = computed(() => {
      if (withBorder.value) {
        return large.value ? 'tw-rounded-xl' : 'tw-rounded-lg'
      }
      return ''
    })

    const wrapperClasses = computed(() => {
      const classes = []

      if (reverse.value) {
        classes.push('tw-flex-row-reverse tw-justify-end')
      }

      if (large.value) {
        classes.push('tw-gap-3')
      } else {
        classes.push('tw-gap-2')
      }

      if (withBorder.value) {
        classes.push(
          'tw-relative tw-border tw-border-solid tw-border-grey-300 tw-p-3 tw-bg-white'
        )
        classes.push(roundnessClass.value)
        if (!disabled.value) {
          classes.push(
            props.variant === 'blue'
              ? 'hover:tw-border-blue-500 [&:has(:focus-visible)]:tw-ring-1 [&:has(:focus-visible)]:tw-ring-blue-500'
              : 'hover:tw-border-primary [&:has(:focus-visible)]:tw-ring-1 [&:has(:focus-visible)]:tw-ring-primary'
          )

          if (
            internalValue.value &&
            (!Array.isArray(internalValue.value) ||
              internalValue.value.includes(value.value))
          ) {
            classes.push(
              props.variant === 'blue'
                ? '!tw-border-blue-500'
                : '!tw-border-primary'
            )
          }
        }
      }

      return [...classes, variant.value]
    })

    const showSwitchButton = computed(() => switchProp.value)

    function handleSwitchButtonClick() {
      if (!checkboxTemplateRef.value) {
        return
      }
      checkboxTemplateRef.value.click()
    }

    return {
      internalId,
      internalValue,
      checkboxClasses,
      labelClasses,
      showSwitchButton,
      switchButtonClasses,
      handleSwitchButtonClick,
      checkboxTemplateRef,
      checkboxContainerClasses,
      switchButtonToggleClasses,
      wrapperClasses,
      roundnessClass,
      cursorClass
    }
  }
})
