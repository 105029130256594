const middleware = {}

middleware['already-logged-in'] = require('../middleware/already-logged-in.ts')
middleware['already-logged-in'] = middleware['already-logged-in'].default || middleware['already-logged-in']

middleware['analytics'] = require('../middleware/analytics.ts')
middleware['analytics'] = middleware['analytics'].default || middleware['analytics']

middleware['flash'] = require('../middleware/flash.ts')
middleware['flash'] = middleware['flash'].default || middleware['flash']

middleware['must-login'] = require('../middleware/must-login.ts')
middleware['must-login'] = middleware['must-login'].default || middleware['must-login']

middleware['needs-guest-complete-profile'] = require('../middleware/needs-guest-complete-profile.ts')
middleware['needs-guest-complete-profile'] = middleware['needs-guest-complete-profile'].default || middleware['needs-guest-complete-profile']

middleware['non-expired-manager'] = require('../middleware/non-expired-manager.ts')
middleware['non-expired-manager'] = middleware['non-expired-manager'].default || middleware['non-expired-manager']

middleware['parking'] = require('../middleware/parking.ts')
middleware['parking'] = middleware['parking'].default || middleware['parking']

middleware['scroll-depth'] = require('../middleware/scroll-depth.ts')
middleware['scroll-depth'] = middleware['scroll-depth'].default || middleware['scroll-depth']

middleware['should-login'] = require('../middleware/should-login.ts')
middleware['should-login'] = middleware['should-login'].default || middleware['should-login']

middleware['trades'] = require('../middleware/trades.ts')
middleware['trades'] = middleware['trades'].default || middleware['trades']

export default middleware
