import { Search } from '~/router/routes/car/classifieds/search/components'

// import { Route } from 'vue-router/types/router'

export const blackFridayRoutes = [
  {
    name: '__classifieds_search_black_friday',
    path: '/black-friday/*',
    component: Search,
    meta: { isSearchRoute: true, wideContainer: true }
  }
]
